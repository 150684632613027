import React, { useContext, useEffect, useRef, useState } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { filterProductsBy, filterProductsByAny, markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Modal from 'react-modal';
import "./new-prods.scss"
import "./animation.scss"
import "./solar-together_NEW.scss"
import Col3 from "../grid/Col3"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import Col2 from "../grid/Col2"
import FormInput from "../olc-framework/FormInput"
import FormSelect from "../olc-framework/FormSelect"
import { GetModal } from "../configurable/Modal"
import Expandable from "../configurable/Expandable"
import FormTextarea from "../olc-framework/FormTextarea"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import "./contact-us-new.scss"



import * as InfogPanels from "~/vectors/infog_panels.inline.svg"

const imagewar2 = require('../../images/shoutout-shirt.png');

import * as SolarTogetherProcess from "~/vectors/solar-together-process-hor-updated.inline.svg"
import * as SolarTogetherProcessVertical from "~/vectors/solar-together-process-vertical-updated.inline.svg"
import ShoutoutNew from "../configurable/ShoutoutNew"

const content = {
  step1: {
    title: 'Welcome Pack',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Your details are sent through to us from Solar Together. We then generate a reference number for you and send you a welcome letter and Letter of Authority (LOA). A LOA is required by the Distribution Network Operators. This letter informs them that you are installing Solar or adding additional items to your current system. You will need to complete the LOA and email it back to us to continue with your installation. Without the LOA we cannot start the process. Your welcome pack will also outline your solar journey with us.</p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step2: {
    title: 'Survey Booking',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Once we have received your Letter of Authority, our sales team will call you on your mobile or telephone to book a surveyor to come around to your property. The Green Energy Together team, will then send you an email regarding your surveying appointment. Our time slots work in AM and PM; as there are several surveys done in a day and we cannot guarantee an exact time. However, our surveyors will give you a call when they are on their way. It’s important to note that any parking permits or payments required, will be up to the customer to organise.</p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step3: {
    title: 'Revised Contract ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Once your survey is complete, the information collected is given to our designers. The Design Team will ensure that you get the best possible system. Our designer’s factor in shading, weather, and panel orientation to name a few. Once your design is complete, you will receive a contract with a quote and design to review.</p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step4: {
    title: 'Contract Acceptance ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>If you are satisfied with your contract and wish to accept, then click the acceptance button located in your contract. Once your contract is accepted, you’ll receive a confirmation email and your Installation Stage Payment request. </p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },

  step5: {
    title: 'Acceptance Deposit',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>At this stage, you will be sent to a secure online platform, whereby you will need to pay 25% of the total contracted amount minus the of Acceptance Deposit, prior to installation on confirmation of an installation appointment. This payment will be taken as a protected Installation Deposit Payment. Once payment has been made, you will receive proof of payment and a link to book your installation.</p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step6: {
    title: 'Installation Arranged ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Our online booking system allows you to see what days we have available and select what works best for you. You’ll need to fill in your details, your reference number, parking requirements and kit delivery. It’s important to note that any parking permits or payments required, will be up to the customer to organise.</p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step7: {
    title: 'Scaffolding Arranged',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>If you require scaffolding our team will contact you. We use independent contractors to install your scaffolding. These contractors will also contact you regarding your erection date, placement, and removal of the scaffold. Our team will arrange a date with the independent scaffolding. We always aim to have this erected 7 days prior to your installation. If your scaffold is not up 24 hours before your installation, we ask that you give us a call so that we can investigate where they are. </p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step8: {
    title: 'Installation ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Two engineers will be at your property on the day of your installation- which may take up to 2-days to install. If we require parking permits, you will need to have 2 of these ready for our installers for the period of your installation. It’s imperative that you are at home for the entire period of your installation and that you have your Wi-Fi password and mobile/tablet to hand for our installers to set up your online app monitoring. During the process of your installation, our installers will need to switch your power off for a few hours whilst they install all electrical items. If you want to learn more about the app visit our <a href="/aftersales"> After Sales Process </a> page.</p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/aftersales'>Aftersales page</a>
          </button>
        </div>
      </>
    )
  },
  step9: {
    title: 'Invoicing',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Once your installation is complete. Our finance team will send through your final invoice. This amount will reflect only what is outstanding. Your invoice will need to be paid within 10-days and once paid you will receive a proof of payment, which is accessible online. </p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },

  step10: {
    title: 'Invoice paid',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Once your invoice has been paid, our team begin working on your Handover Pack.</p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step11: {
    title: 'Scaffolding Strike ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Once you installation is complete, we will let the scaffolding company know to arrange a strike date with you.

            </p>
          </div>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/contact-us'>Ask a question</a>
          </button>
        </div>
      </>
    )
  },
  step12: {
    title: 'Handover Pack ',
    content: (
      <>
        <div className="modal_content_container" >
          <p>You will be sent your handover pack electronically after you have paid your final invoice. We do not send this out in paper format due to our environmental policies, however, under special circumstances, we may be able to help with this.

          </p>
        </div>
        {/* <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>When?  </strong>  Between day x and day x</p>
          </div>

        </div>
        <div className="modal_icon_container">
          <div>
            <Icon alias="sun" className="modal_icon" />
          </div>
          <div className="modal_icon_text" >
            <p><strong>How?  </strong>  You will receive x via email</p>
          </div>
        </div> */}
        <div className="modal_btn_container">
          <button className="modal_btn">
            <a href='/aftersales'>Aftersales page</a>
          </button>
        </div>
      </>
    )
  },
}



const SolarTogether = ({ markdownNodes, location }) => {
  const productsBlockRef = React.createRef() as React.RefObject<HTMLElement>

  const { customerType, setCustomerType } = useContext(CustomerTypeContext);

  const isDomestic = React.useMemo(() => customerType === "domestic", [customerType]);

  const [hasUsedFilters, setHasUsedFilters] = React.useState(true);


  //START DEVON LONDON RENDER LOGIC

  const [schemeCity, setSchemeCity] = useState('default');

  const defaultScheme = React.useMemo(() => schemeCity === "default", [schemeCity]);
  const london22Scheme = React.useMemo(() => schemeCity === "London22", [schemeCity]);
  const camb22Scheme = React.useMemo(() => schemeCity === "Camb22", [schemeCity]);
  const war22Scheme = React.useMemo(() => schemeCity === "War22", [schemeCity]);

  const [schemeCity21, setSchemeCity21] = useState('default21');
  const defaultScheme21 = React.useMemo(() => schemeCity21 === "default21", [schemeCity21]);
  const london21Scheme = React.useMemo(() => schemeCity21 === "London21", [schemeCity21]);
  const devon21Scheme = React.useMemo(() => schemeCity21 === "Devon21", [schemeCity21]);



  // ENDS DEVON LONDON RENDER LOGIC


  // START CONTACT US LOGIN

  const { state = {} } = location

  const logFormSubmitEvent = () => {
    if (window) {
      window.dataLayer = window.dataLayer || []
    }

    const eventData = {
      category: "Form",
      action: "Submit",
      label: "Contact Us",
    }

    trackCustomEvent(eventData)
  }

  // ENDS CONTACT US LOGIC

  // video full



  // video full

  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000';
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);

  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);

  //END MODAL LOGIC

  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {
        setBrandFilter(brand);
        closeModal();
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  return (
    <div>
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={getModalTitle()}
      >
        {getModalContent()}
      </GetModal>

      <Hero imageUrl="/images/_breadcrumb17.png" compact>
        <Heading level={1} underlined>
          Solar Together
        </Heading>
      </Hero>
      <Block>
        <Section className="introSec"  >
          <div className="container container--column illustrated-house-block"
          >
            <Heading level={1}>Solar for everyone </Heading>
            <p className="bold-formatting">
              Solar Together is a unique group-buying scheme for solar photovoltaic (PV) panels and battery storage. This scheme will make solar PV and batteries more accessible and more affordable for a great number of people. </p>
            <p className="bold-formatting">
              With a group-buying scheme, it becomes easier to buy your solar PV system and battery storage. And by buying together, you gain a competitive price. iChoosr is the owner of Solar together and a group-buying specialist in this field, who cooperates with participating local authorities to deliver the scheme.
            </p>
          </div>
        </Section>
        <Section >
          <div className="container" id="solar-together" >
            <Heading level={3} underlined>Solar Together Process </Heading>
            <div className="row">
              <Col8>
                <TickList>
                  <li>Register your interest on the Solar Together Page</li>
                  <li>An auction is held with pre-vetted installers</li>
                  <li>A Personal recommendation is sent to you </li>
                  <li>You decide if you want to take up the recommendation & proceed </li>
                </TickList>
                <div style={{ marginTop: 30 }}>
                  <p  > Your details are then sent through to start the next stage of the process with Green Energy Together </p>
                </div>
              </Col8>
              <Col4>
                <img src={require('../../images/solar-together-logo.png')} alt="" />
              </Col4>
            </div>
          </div>
        </Section>
      </Block>



      <ShoutoutNew
        color={'#70b33b'}
        image={require('../../images/shoutout-shirt.png')}
        text={
          <>
            <Heading level={2} underlined >About us </Heading>
            <p>
              It all began as a family solar panel business in 2009 in Hertfordshire, England. Over the years, the business has grown considerably, both fuelling and sustaining the increasing awareness and adoption of renewable energy sources. Today, Green Energy Together is a leading British solar energy company with over 400MW of solar installed.
            </p>
            <BlockCTA
              navy
              url="/about"
              arrow="right">
              Learn more
            </BlockCTA>
          </>
        }
      />

      <Block>
        <Section className="schematicSec" is="process"  >
          <div className="container" >
            <Col12>
              <Heading level={2} underlined>Our Process</Heading>
            </Col12>
            <div className="row">
              <Col12>
                <p >
                  Once you have chosen to go ahead with the Solar together scheme, we receive your details and start the process for your installation:
                </p>
              </Col12>
            </div>
          </div>
          <div className="row"  >
            <div >
              <div className="infocontainer hidden-xs" style={{ padding: "6%  15% 6% 15%" }} >

                <SolarTogetherProcess />
                <button className="btn btn-solar-together --hor step1" onClick={() => onOpenModalFor('step1')} />
                <button className="btn btn-solar-together --hor step2" onClick={() => onOpenModalFor('step2')} />
                <button className="btn btn-solar-together --hor step3" onClick={() => onOpenModalFor('step3')} />
                <button className="btn btn-solar-together --hor step4" onClick={() => onOpenModalFor('step4')} />
                <button className="btn btn-solar-together --hor step5" onClick={() => onOpenModalFor('step5')} />
                <button className="btn btn-solar-together --hor step6" onClick={() => onOpenModalFor('step6')} />
                <button className="btn btn-solar-together --hor step7" onClick={() => onOpenModalFor('step7')} />
                <button className="btn btn-solar-together --hor step8" onClick={() => onOpenModalFor('step8')} />
                <button className="btn btn-solar-together --hor step9" onClick={() => onOpenModalFor('step9')} />
                <button className="btn btn-solar-together --hor step10" onClick={() => onOpenModalFor('step10')} />
                <button className="btn btn-solar-together --hor step11" onClick={() => onOpenModalFor('step11')} />
                <button className="btn btn-solar-together --hor step12" onClick={() => onOpenModalFor('step12')} />
              </div>

              <div className="infocontainer visible-xs" style={{ padding: "8.5%" }} >
                <SolarTogetherProcessVertical />
                <button className="btn btn-solar-together --ver step1" onClick={() => onOpenModalFor('step1')} />
                <button className="btn btn-solar-together --ver step2" onClick={() => onOpenModalFor('step2')} />
                <button className="btn btn-solar-together --ver step3" onClick={() => onOpenModalFor('step3')} />
                <button className="btn btn-solar-together --ver step4" onClick={() => onOpenModalFor('step4')} />
                <button className="btn btn-solar-together --ver step5" onClick={() => onOpenModalFor('step5')} />
                <button className="btn btn-solar-together --ver step6" onClick={() => onOpenModalFor('step6')} />
                <button className="btn btn-solar-together --ver step7" onClick={() => onOpenModalFor('step7')} />
                <button className="btn btn-solar-together --ver step8" onClick={() => onOpenModalFor('step8')} />
                <button className="btn btn-solar-together --ver step9" onClick={() => onOpenModalFor('step9')} />
                <button className="btn btn-solar-together --ver step10" onClick={() => onOpenModalFor('step10')} />
                <button className="btn btn-solar-together --ver step11" onClick={() => onOpenModalFor('step11')} />
                <button className="btn btn-solar-together --ver step12" onClick={() => onOpenModalFor('step12')} />
              </div>
            </div>
          </div>
        </Section>


        <Section className="introSec" id="scheme-cards new">
        <Heading level={2} underlined>New Schemes</Heading>
          <div className="buttons__row" style={{marginTop:'2em'}}>
            <button className="tab__button london22"
              onClick={e => setSchemeCity('London22')}

            >
              <Heading level={2}>
                London 22
              </Heading>
            </button>

            <button className="tab__button camb22"
              onClick={e => setSchemeCity('Camb22')}
            >
              <Heading level={2}>
                Cambridge
              </Heading>
            </button>

            <button className="tab__button war22"
              onClick={e => setSchemeCity('War22')}

            >
              <Heading level={2}>
              Warwickshire
              </Heading>
            </button>
          </div>

          {(london22Scheme || defaultScheme) && (
            <div className="cards__container london" >
              <div className="cards__inner contact">
                <div className="row">
                  <Col12>
                    <Heading level={2} >Solar Together London 2022</Heading>
                    <p style={{ marginBottom: 40 }}>
                      Welcome to the London 2022 Scheme. This is for all customers who signed up through Solar Together in 2022.Here you'll be able to find simple ways to contact Green Energy Together who is you installer. When contacting us please have our SPUK ready or place it in the subject line of any email sent
                    </p>
                    {/* <div className="div" style={{ marginBottom: 40 }}>
                      <Heading level={3} underlined>Contact</Heading>
                    </div> */}
                  </Col12>
                  <Col12>
                    <div className="numbersBlock " >
                      <div className="numbersBlockFirstItem ">
                        <img src={require('../../images/st.png')} style={{ margin: 'auto' }} />
                        <div className="numbersBlockItemTitle">
                          <strong>
                            Solar Together
                          </strong>
                        </div>
                        <div>
                          <a
                            href="tel:(0)2081672206"
                            style={{

                            }}
                          >
                            {/* <Icon alias="phone" /> */}
                            02081672206
                          </a>
                        </div>
                        <div>
                          <a
                            href="mailto:solar.together@get-uk.com"
                            style={{

                            }}
                          >
                            {/* <Icon alias="email" /> */}
                            solar.together@get-uk.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col12>
                </div>
              </div>
              <div className="cards__inner products">
                <div className="row">
                  <Col12>
                    <Heading level={3} underlined>PV system components </Heading>
                    <p >
                      Your solar system will include a combination of the products below or the equivalent to the original offering
                    </p>
                    <div className="products-ticklist">
                      <TickList>

                        <span >Panels:</span>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/phono_solar_full_black_module_395w.pdf">
                            Phono Solar Full Black Module 400w
                          </a>
                        </li>
                        <span><br />Inverter:</span>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-Foxess-s-series-inverter 3.6.pdf">
                            S Series Single MPPT Inverter
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-single-phase-f-series 5.0.pdf">
                            F Series Dual MPPt Inverter
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-sp-hybrid.pdf">
                            Single-Phase Hybrid Inverter
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-hyd-3k-6k.pdf">
                            Sofar HYD Single-Phase
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-sp-ac-foxess.pdf">
                            Fox ESS AC Charger
                          </a>
                          <span style={{ textDecoration: 'none', color: 'black', display: 'inline', fontWeight: 'normal' }}>
                            &nbsp;(retrofit only)
                          </span>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-me-3000.pdf">
                            SofarSolar ME 3000SP
                          </a>
                          <span style={{ textDecoration: 'none', color: 'black', display: 'inline', fontWeight: 'normal' }}>
                            &nbsp;(retrofit only)
                          </span>
                        </li>
                        <span><br />Battery:</span>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-foxess-battery-module-hv2600.pdf">
                            FoxESS HV2600
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-amasstore-battery-gtx5000.pdf">
                            Amasstore Battery GTX5000
                          </a>
                        </li>
                        <span><br />Extras:</span>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-tigo.pdf">
                            Tigo Optimiser TS4-A-O
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-iboost.pdf">
                            Solar Iboost+
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-ba2_flat_roof_kit.pdf">
                            Moss Ballasted Installation
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-moss-huade-pitched-roof.pdf">
                            Moss Huade Pitched Roof Mounting System
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-enviroguard-solar-panel-bird-exclusion-system-product-spec-sheet.pdf">
                            Enviroguard Bird Exclusion System
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-brostrend_wi-fi_range_extender_signal_booster_manual_e1_v1_v2.pdf">
                            BrosTrend Ac1200 WiFi Booster
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-evie-flyer-7.2kw-smart-home.pdf"> Evie
                            Smart Home Charging
                          </a>
                        </li>


                      </TickList>

                    </div>
                  </Col12>
                </div>
                <div className="row" style={{ marginTop: "3em", fontSize: ".8em" }}>
                  <Col12>
                    <p>
                      For full terms and conditions please visit: <a target="_blank" style={{ color: "rgb(112, 179, 59)", textDecoration: "underline", fontWeight: "normal" }} href="/london-terms">https://get-uk.com/london-terms</a>
                    </p>
                  </Col12>
                </div>
              </div>
            </div>
          )}
          {camb22Scheme && (
            <div className="cards__container devon">
              <div className="cards__inner contact">
                <div className="row">
                  <Col12>
                    <Heading level={2} >Solar Together Cambridge 2022</Heading>
                    <p style={{ marginBottom: 40 }}>
                      Welcome to the Cambridge Solar Together Scheme. Here you'll be able to find simple ways to contact Green Energy Together who is you installer. When contacting us please have our SPUK ready or place it in the subject line of any email sent
                    </p>
                    {/* <div className="div" style={{ marginBottom: 40 }}>
                      <Heading level={3} underlined>Contact</Heading>
                    </div> */}
                  </Col12>

                  <Col12>
                    <div className="numbersBlock " >
                      <div className="numbersBlockFirstItem ">
                        <img src={require('../../images/st.png')} style={{ margin: 'auto' }} />
                        <div className="numbersBlockItemTitle">
                          <strong>
                            Solar Together
                          </strong>
                        </div>
                        <div>
                          <a
                            href="tel:02081672205"
                            style={{

                            }}
                          >
                            {/* <Icon alias="phone" /> */}
                            02081672205

                          </a>
                        </div>
                        <div>
                          <a
                            href="mailto:solar.together@get-uk.com"
                            style={{

                            }}
                          >
                            {/* <Icon alias="email" /> */}
                            solar.together@get-uk.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col12>
                </div>
              </div>
              <div className="cards__inner products">
                <div className="row">
                  <Col12>
                    <Heading level={3} underlined>PV system components </Heading>
                    <p >
                      Your solar system will include a combination of the products below or the equivalent to the original offering
                    </p>
                    <div  className="products-ticklist" >
                    <TickList>

                      <span >Panels:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/phono_solar_full_black_module_395w.pdf">
                          Phono Solar Full Black Module 400w
                        </a>
                      </li>
                      <span><br />Inverter:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-Foxess-s-series-inverter 3.6.pdf">
                          S Series Single MPPT Inverter
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-single-phase-f-series 5.0.pdf">
                          F Series Dual MPPt Inverter
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-sp-hybrid.pdf">
                          Single-Phase Hybrid Inverter
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-hyd-3k-6k.pdf">
                          Sofar HYD Single-Phase
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-sp-ac-foxess.pdf">
                          Fox ESS AC Charger
                        </a>
                        <span style={{ textDecoration: 'none', color: 'black', display: 'inline', fontWeight: 'normal' }}>
                          &nbsp;(retrofit only)
                        </span>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-me-3000.pdf">
                          SofarSolar ME 3000SP
                        </a>
                        <span style={{ textDecoration: 'none', color: 'black', display: 'inline', fontWeight: 'normal' }}>
                          &nbsp;(retrofit only)
                        </span>
                      </li>
                      <span><br />Battery:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-foxess-battery-module-hv2600.pdf">
                          FoxESS HV2600
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-amasstore-battery-gtx5000.pdf">
                          Amasstore Battery GTX5000
                        </a>
                      </li>
                      <span><br />Extras:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-tigo.pdf">
                          Tigo Optimiser TS4-A-O
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-iboost.pdf">
                          Solar Iboost+
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-ba2_flat_roof_kit.pdf">
                          Moss Ballasted Installation
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-moss-huade-pitched-roof.pdf">
                          Moss Huade Pitched Roof Mounting System
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-enviroguard-solar-panel-bird-exclusion-system-product-spec-sheet.pdf">
                          Enviroguard Bird Exclusion System
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-brostrend_wi-fi_range_extender_signal_booster_manual_e1_v1_v2.pdf">
                          BrosTrend Ac1200 WiFi Booster
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-evie-flyer-7.2kw-smart-home.pdf"> Evie
                          Smart Home Charging
                        </a>
                      </li>


                    </TickList>
                    </div>

                  </Col12>

                </div>
                <div className="row" style={{ marginTop: "3em", fontSize: ".8em" }}>
                  <Col12>
                    <p>
                      For full terms and conditions please visit: <a target="_blank" style={{ color: "rgb(112, 179, 59)", textDecoration: "underline", fontWeight: "normal" }} href="/cambridge-terms">https://get-uk.com/cambridge-terms</a>
                    </p>
                  </Col12>
                </div>
              </div>
            </div>
          )}
          {war22Scheme && (
            <div className="cards__container devon">
              <div className="cards__inner contact">
                <div className="row">
                  <Col12>
                    <Heading level={2} >Solar Together Warwickshire </Heading>
                    <p style={{ marginBottom: 40 }}>
                    We’re here to install your solar and/or battery system as part of the Solar Together scheme. On this page you will be able to learn a bit about us including our previous and current projects and schemes. We are pleased to let you know that the products which we are offering will be of a higher output and our solar panels are VAT free. We are pleased to have you on board and cannot wait to Welcome you to the future of energy. 
                    </p>
                  </Col12>

                  <Col12>
                    <div className="numbersBlock " >
                      <div className="numbersBlockFirstItem ">
                        <img src={require('../../images/st.png')} style={{ margin: 'auto' }} />
                        <div className="numbersBlockItemTitle">
                          <strong>
                            Solar Together
                          </strong>
                        </div>
                        <div>
                          <a
                            href="tel:01920452220"
                            style={{

                            }}
                          >
                            {/* <Icon alias="phone" /> */}
                            01920452220

                          </a>
                        </div>
                        <div>
                          <a
                            href="mailto:solar.together@get-uk.com"
                            style={{

                            }}
                          >
                            {/* <Icon alias="email" /> */}
                            solar.together@get-uk.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col12>
                </div>
              </div>
              <div className="cards__inner products">
                <div className="row">
                  <Col12>
                    <Heading level={3} underlined>PV system components </Heading>
                    <p >
                      Your solar system will include a combination of the products below or the equivalent to the original offering
                    </p>
                    <div  className="products-ticklist" >
                    <TickList>

                      <span >Panels:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/phono_solar_full_black_module_395w.pdf">
                          Phono Solar Full Black Module 400w
                        </a>
                      </li>
                      <span><br />Inverter:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-Foxess-s-series-inverter 3.6.pdf">
                          S Series Single MPPT Inverter
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-single-phase-f-series 5.0.pdf">
                          F Series Dual MPPt Inverter
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-sp-hybrid.pdf">
                          Single-Phase Hybrid Inverter
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-hyd-3k-6k.pdf">
                          Sofar HYD Single-Phase
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-sp-ac-foxess.pdf">
                          Fox ESS AC Charger
                        </a>
                        <span style={{ textDecoration: 'none', color: 'black', display: 'inline', fontWeight: 'normal' }}>
                          &nbsp;(retrofit only)
                        </span>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-me-3000.pdf">
                          SofarSolar ME 3000SP
                        </a>
                        <span style={{ textDecoration: 'none', color: 'black', display: 'inline', fontWeight: 'normal' }}>
                          &nbsp;(retrofit only)
                        </span>
                      </li>
                      <span><br />Battery:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-foxess-battery-module-hv2600.pdf">
                          FoxESS HV2600
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-amasstore-battery-gtx5000.pdf">
                          Amasstore Battery GTX5000
                        </a>
                      </li>
                      <span><br />Extras:</span>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-tigo.pdf">
                          Tigo Optimiser TS4-A-O
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-iboost.pdf">
                          Solar Iboost+
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-ba2_flat_roof_kit.pdf">
                          Moss Ballasted Installation
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-moss-huade-pitched-roof.pdf">
                          Moss Huade Pitched Roof Mounting System
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-enviroguard-solar-panel-bird-exclusion-system-product-spec-sheet.pdf">
                          Enviroguard Bird Exclusion System
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-brostrend_wi-fi_range_extender_signal_booster_manual_e1_v1_v2.pdf">
                          BrosTrend Ac1200 WiFi Booster
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://get-solar-together-22.netlify.app/datasheets/get-evie-flyer-7.2kw-smart-home.pdf"> Evie
                          Smart Home Charging
                        </a>
                      </li>


                    </TickList>
                    </div>

                  </Col12>

                </div>
                <div className="row" style={{ marginTop: "3em", fontSize: ".8em" }}>
                  <Col12>
                    <p>
                      For full terms and conditions please visit: <a target="_blank" style={{ color: "rgb(112, 179, 59)", textDecoration: "underline", fontWeight: "normal" }} href="/warwickshire-terms">https://get-uk.com/warwickshire-terms</a>
                    </p>
                  </Col12>
                </div>
              </div>
            </div>
          )}

        </Section>

        <Section >
          <Heading level={2} underlined>Previous Schemes</Heading>
          <p>
            These are all the schemes we have been apart of, if you have any questions, queries or need to report a fault, then please find  your area below and contact us directly for any help needed.
          </p>
        </Section>

        <Section className="introSec" id="scheme-cards old">
          <div className="buttons__row">
            <button className="tab__button london21"
              onClick={e => setSchemeCity21('London21')}

            >
              <Heading level={2}>
                London 21
              </Heading>
            </button>
            <button className="tab__button devon21"
              onClick={e => setSchemeCity21('Devon21')}
            >
              <Heading level={2} >
                Devon 21
              </Heading>
            </button>
          </div>

          {(london21Scheme || defaultScheme21) && (
            <div className="cards__container london" >
              <div className="cards__inner contact">
                <div className="row">
                  <Col12>
                    <Heading level={2} >Solar Together London 2021</Heading>
                    <p style={{ marginBottom: 40 }}>
                      This scheme is for customer who signed up in 2021.
                    </p>
                    {/* <div className="div" style={{ marginBottom: 40 }}>
                      <Heading level={3} underlined>Contact</Heading>
                    </div> */}
                  </Col12>

                  <Col12>
                    <div className="numbersBlock " >
                      <div className="numbersBlockFirstItem ">
                        <img src={require('../../images/st.png')} style={{ margin: 'auto' }} />
                        <div className="numbersBlockItemTitle">
                          <strong>
                            Solar Together
                          </strong>
                        </div>
                        <div>
                          <a
                            href="tel:02039898987"
                            style={{

                            }}
                          >
                            {/* <Icon alias="phone" /> */}
                            London: 020 3989 8987
                          </a>
                        </div>
                        <div>
                          <a
                            href="mailto:solar.together@get-uk.com"
                            style={{

                            }}
                          >
                            {/* <Icon alias="email" /> */}
                            solar.together@get-uk.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col12>
                </div>
              </div>
              <div className="cards__inner products">
                <div className="row">
                  <Col12>

                    <Heading level={3} underlined>PV system components </Heading>
                    <p >
                      Your solar system will include a combination of the products below or the equivalent to the original offering
                    </p>
                    <TickList>
                      <li><span >Panels:</span>
                        <a target="_blank" href="https://get-uk-docs.netlify.app/JA_SOLAR_360W-375W.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }} > JA Solar 360 MMB Half-Cell Black Module </a>, <a target="_blank" href="https://get-datasheets.netlify.app/qpeak.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }} > Q.PEAK Duo BLK ML-G9 375w </a>
                      </li>
                      <li><span>Inverter:</span>
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/63c9c630ed1d0e9854c90d94aa6d6e16/get-sp-hybrid.pdf"> FoxESS Single-Phase Hybrid Inverter  </a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/2de3d3a470e1d684ce499c20f44979ab/get-single-phase-f-series.pdf"> F Series Dual MPPT Inverter  </a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/63c9c630ed1d0e9854c90d94aa6d6e16/get-sp-hybrid.pdf"> FoxESS Hybrid   </a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/df0417b8100ae5dd5e502267d4fdb6e9/get-solar-edge-power-optimizer.pdf"> SolarEdge Optimised option </a>
                      </li>
                      <li>Battery:
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/8d4cfe267bd55ee49ee56e638eaf00a6/get-foxess-battery-module-hv2600.pdf"> FoxESS HV2600 Battery Module </a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/4499842ec8e96f30514dc623a72d965d/get-amasstore-battery-gtx2000.pdf"> Sofar Amasstore battery GTX2000 </a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/b33b69abc222a770c153316e6f888574/get-amasstore-battery-gtx3000.pdf"> Sofar Amasstore battery GTX3000  </a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/5979af765ef8b7e03d7aa30314b7afe2/get-amasstore-battery-gtx5000.pdf"> Sofar Amasstore battery GTX5000  </a>
                      </li>
                      <li>Optimized Option:
                        <a target="_blank" href="https://get-uk.com/static/df0417b8100ae5dd5e502267d4fdb6e9/get-solar-edge-power-optimizer.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}> SolarEdge Panel Power Optimisers </a> and
                        <a target="_blank" href="https://get-uk-docs.netlify.app/tigo.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}> Tigo Optimiser TS4-A-O (700W)</a>
                      </li>
                      <li>Power Controller:
                        <a target="_blank" href="https://get-uk.com/static/843ac86f1acb5d47da2956ebaf19682c/get-immersun-automatic-power-controller.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}> Immersun automatic</a>
                      </li>
                      <li>Optional Extra:
                        <a target="_blank" href="https://get-uk.com/static/9c60ad99c5065597e08ca21d651f72e2/get-enviroguard-solar-panel-bird-exclusion-system-product-spec-sheet.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}>  Enviroguard Panel Bird Exclusion </a>,
                        <a target="_blank" href="https://get-uk.com/static/7635eb0b2862fa9ed751943f50d83e9b/get-brostrend_wi-fi_range_extender_signal_booster_manual_e1_v1_v2.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}> BrosTrend AC1200 WiFi Booster</a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/7e19a0ac2596094b38dea005e723dc9b/renusol-product-catalogue.pdf"> Renusol Mounting System</a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/b68e86b0722e23d83004f2401b47292a/get-evie-flyer-7.2kw-smart-home.pdf"> EVIE Smart Home Charging</a> and
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk-docs.netlify.app/BA2_flat_roof_kit.pdf"> BA2 Ballasted Flat Roof Kit</a>
                      </li>


                    </TickList>
                  </Col12>
                </div>
                <div className="row" style={{ marginTop: "3em", fontSize: ".8em" }}>
                  <Col12>
                    <p>
                      For full terms and conditions please visit: <a target="_blank" style={{ color: "rgb(112, 179, 59)", textDecoration: "underline", fontWeight: "normal" }} href="/london21-terms">https://get-uk.com/london21-terms</a>
                    </p>
                  </Col12>
                </div>
              </div>
            </div>
          )}
          {devon21Scheme && (
            <div className="cards__container devon">
              <div className="cards__inner contact">
                <div className="row">
                  <Col12>
                    <Heading level={2} >Solar Together Devon 2021</Heading>
                    <p style={{ marginBottom: 40 }}>
                      Registration and applications for this scheme are now closed: Closed 14/1/2022
                    </p>
                    {/* <div className="div" style={{ marginBottom: 40 }}>
                      <Heading level={3} underlined>Contact</Heading>
                    </div> */}
                  </Col12>
                  {/* <Col8>
                    <form

                      className="form form--full-width solar_tog"
                      onSubmit={() => {
                        logFormSubmitEvent()
                      }}
                      action="https://formspree.io/f/xwkrpynw"
                      method="POST"
                      name="contact-us"
                    // data-netlify="true" -- to use netlify forms
                    >
                      <div className="row">
                        <Col12>
                          <FormInput
                            name="full-name"
                            label="Full name"
                            placeholder="Type your full name"
                            value={state?.name}
                            required
                          />
                        </Col12>
                        <Col12>
                          <FormInput
                            name="email"
                            label="Email"
                            type="email"
                            placeholder="Type your email"
                            value={state?.email}
                            required
                          />
                        </Col12>
                      </div>
                      <FormTextarea
                        name="message"
                        label="Message"
                        placeholder="Type your message"
                        required
                      />
                      <div className="form__actions">
                        <BlockCTA fullWidth large submit>
                          Send
                        </BlockCTA>
                      </div>
                    </form>
                  </Col8> */}
                  <Col12>
                    <div className="numbersBlock " >
                      <div className="numbersBlockFirstItem ">
                        <img src={require('../../images/st.png')} style={{ margin: 'auto' }} />
                        <div className="numbersBlockItemTitle">
                          <strong>
                            Solar Together
                          </strong>
                        </div>
                        <div>
                          <a
                            href="tel:02038669896"
                            style={{

                            }}
                          >
                            {/* <Icon alias="phone" /> */}
                            020 3866 9896
                          </a>
                        </div>
                      </div>
                    </div>
                  </Col12>
                </div>
              </div>
              <div className="cards__inner products">
                <div className="row">
                  <Col12>
                    <Heading level={3} underlined>PV system components </Heading>
                    <p >
                      Your solar system will include a combination of the products below or the equivalent to the original offering
                    </p>
                    <TickList>
                      <li><span >Panels:</span>
                        <a target="_blank" href="https://www.get-uk.com/static/597e182d52d47ede766c1023225e376e/get-135-335w-trinasolar-honeybacl-mono-module.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }} > Trinasolar HoneyBlack Mono Module</a></li>
                      <li>Inverter:
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/63c9c630ed1d0e9854c90d94aa6d6e16/get-sp-hybrid.pdf"> FoxESS Single-Phase Hybrid Inverter  </a>,
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/df0417b8100ae5dd5e502267d4fdb6e9/get-solar-edge-power-optimizer.pdf"> SolarEdge Optimised option </a>
                      </li>
                      <li>Battery:
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/8d4cfe267bd55ee49ee56e638eaf00a6/get-foxess-battery-module-hv2600.pdf"> FoxESS HV2600 Battery Module </a>
                      </li>
                      <li>Optimized Option:
                        <a target="_blank" href="https://get-uk.com/static/df0417b8100ae5dd5e502267d4fdb6e9/get-solar-edge-power-optimizer.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}> SolarEdge Panel Power Optimisers </a>
                      </li>
                      <li>Power Controller:
                        <a target="_blank" href="https://get-uk.com/static/843ac86f1acb5d47da2956ebaf19682c/get-immersun-automatic-power-controller.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}> Immersun automatic</a>
                      </li>
                      <li>Optional Extra:
                        <a target="_blank" href="https://get-uk.com/static/9c60ad99c5065597e08ca21d651f72e2/get-enviroguard-solar-panel-bird-exclusion-system-product-spec-sheet.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}>  Envirogaurd Panel Bird Exclusion </a>,
                        <a target="_blank" href="https://get-uk.com/static/7635eb0b2862fa9ed751943f50d83e9b/get-brostrend_wi-fi_range_extender_signal_booster_manual_e1_v1_v2.pdf" style={{ color: "#3c96c5", fontWeight: "normal" }}> BrosTrend AC1200 WiFi Booster</a> and
                        <a target="_blank" style={{ color: "#3c96c5", fontWeight: "normal" }} href="https://get-uk.com/static/7e19a0ac2596094b38dea005e723dc9b/renusol-product-catalogue.pdf"> Renusol Mounting System</a>
                      </li>


                    </TickList>
                  </Col12>
                </div>
                <div className="row" style={{ marginTop: "3em", fontSize: ".8em" }}>
                  <Col12>
                    <p>
                      For full terms and conditions please visit: <a target="_blank" style={{ color: "rgb(112, 179, 59)", textDecoration: "underline", fontWeight: "normal" }} href="/devon-terms">https://get-uk.com/devon-terms</a>
                    </p>
                  </Col12>
                </div>
              </div>
            </div>
          )}

        </Section>





        <Section >
          <div className="container" id="solar-together" style={{ marginTop: 40 }}>


            <div className="row">
              <Col8>
                <Heading level={3} underlined>Solar Together Scheme</Heading>
                <p className="bold-formatting">
                  To find out more about this exciting opportunity check out the following video.
                </p>
                <TickList>
                  <li>To read Solar Together Frequently Asked Questions page <a target="_blank" style={{ color: "#3c96c5", textDecoration: "underline" }} href="https://www.solartogether.co.uk/info/5-most-frequently-asked-questions">click here</a></li>
                  <li>Find out more about the registration process at <a style={{ color: "#3c96c5", textDecoration: "underline" }} target="_blank" href="https://www.youtube.com/watch?v=34Ean-4rUBw">Solar Together Youtube channel.</a> </li>
                </TickList>
              </Col8>
              <Col4>
                <div
                  className="video"
                  style={{

                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,

                    height: 0
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                    src={`https://www.youtube.com/embed/34Ean-4rUBw`}
                    frameBorder="0"
                  />
                </div>
              </Col4>
            </div>
          </div>
        </Section>






      </Block>
    </div>
  )
}

export default SolarTogether
